var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-row tw-items-start tw-justify-between tw-pt-1 tw-px-3 tw-space-x-3 tw-w-full tw-overflow-x-hidden",class:_vm.darkGrayCss},[_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-none tw-space-x-3 tw-overflow-x-hidden"},[_c('div',{staticClass:"tw-w-20 tw-flex tw-flex-row tw-flex-none tw-justify-between"},[(_vm.runResult.status == 'success')?_c('b-icon',{staticClass:"tw-mt-1",class:_vm.grayCss,attrs:{"icon":"check","size":"is-small"}}):(
          _vm.frontendState == 'kickoff_error' || _vm.runResult.status == 'failed'
        )?_c('b-icon',{staticClass:"tw-mt-1",class:_vm.redCss,attrs:{"icon":"exclamation","size":"is-small"}}):(_vm.frontendState == 'running')?_c('b-icon',{staticClass:"tw-mt-1",class:_vm.blueCss,attrs:{"icon":"circle-notch","size":"is-small","custom-class":"fa-spin"}}):_vm._e(),(
          (_vm.runResult.payloadDisplayType === 'json.table.rowwise' ||
            _vm.runResult.payloadDisplayType === 'json.multiset.rowwise' ||
            _vm.runResult.payloadDisplayType === 'csv') &&
          _vm.runResult.totalRowsReturned
        )?_c('div',{staticClass:"tw-flex-none tw-text-gray-500"},[_vm._v(" "+_vm._s(_vm._plural(_vm.runResult.totalRowsReturned, "row"))+" ")]):_vm._e()],1),_c('b-tooltip',{attrs:{"multilined":"","label":"Data is too large to display in the table. Download the file to view the\n    full dataset.","type":"is-primary is-light","position":"is-right"}},[(_vm.runResult.isTruncated)?_c('div',{staticClass:"tw-text-xs tw-shadow-sm tw-flex tw-flex-row tw-rounded tw-text-purple-900 tw-bg-purple-50 tw-px-2 tw-py-1"},[_vm._v(" Truncated ")]):_vm._e()]),(!_vm.isMobile && _vm.runCarousel.length > 1)?_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-none tw-overflow-y-hidden tw-overflow-x-scroll",class:_vm.lighterGrayCss},_vm._l((_vm.runramsToShow),function(v,k,i){return _c('span',{key:k,staticClass:"tw-flex-none"},[_c('span',{staticClass:"tw-font-bold",class:i > 0 ? 'tw-pl-2' : ''},[_vm._v(_vm._s(k)+": ")]),_c('span',[_vm._v(_vm._s(v === "" ? '""' : v === null ? "null" : _vm._isDate(v) ? v.toISOString() : v))])])}),0):_vm._e()],1),(
      _vm.runResult.status == 'success' &&
      (_vm.runResult.payloadDisplayType == 'csv' ||
        _vm.runResult.payloadDisplayType == 'json.table.rowwise' ||
        _vm.runResult.payloadDisplayType === 'json.multiset.rowwise')
    )?_c('div',{staticClass:"tw-flex-shrink-0 tw-cursor-pointer tw-bg-white tw-mt-1",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('emitShowFullScreenDataModal')}}},[_c('b-icon',{staticClass:"hover:tw-text-black tw-text-gray-400 tw-cursor-pointer tw-mr-2",attrs:{"icon":"search","size":"is-small"}}),_c('b-icon',{staticClass:"hover:tw-text-black tw-text-gray-400 tw-cursor-pointer",attrs:{"icon":"expand-arrows-alt","size":"is-small"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }