import { isObject, lfirst, oitems } from "./flang";

export function zz(...s: any[]) {
  if (s.length === 0) {
    const fakeError = new Error();
    const stack = fakeError.stack;
    if (stack && stack.split("\n").length >= 3) {
      console.log(stack.split("\n")[2]);
    }
  } else if (s.length === 1) {
    const first = lfirst(s);
    if (isObject(first)) {
      for (const [k, v] of oitems(first)) {
        console.log(k, v);
      }
    } else {
      console.log(first);
    }
  } else if (s.length > 1) {
    console.log(...s);
  }
}
