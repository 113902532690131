import Vue from "vue";
import api from "@/shelves/api";
import MissionControl from "@/MissionControl";
import * as Msal from "@azure/msal-browser";
import ReportBugModalVue from "@/components/junk-drawer/ReportBugModal.vue";
import StackTrace from "stacktrace-js";
import { FlankUser } from "@/types/general-flank-types";
import { adClient } from "@/plugins/adClient";
import { auth0Client } from "@/plugins/auth0Client";
import { demoClient } from "@/plugins/demoClient";
import { apiUserObj, redirectingFromAuth0Splash } from "@/plugins/authUtils";
import {
  beep,
  lfirst,
  strim,
  oget,
  windowToFullPath,
  odrop,
  urlJoin,
  fullPathToPath,
  timeout,
  isArray2,
} from "@/brains/flang";
import { zz } from "@/brains/malf";
import { serializeError } from "serialize-error";
import { transformPayload } from "@/shelves/transformations";
import {
  redToast,
  blueToast,
  greenToast,
} from "@/components/comflonents/buefyActions";
import {
  pathToQueryParams,
  queryParamsToQueryString,
  interpolateRunrams,
  strToRunrams,
  runramsToStr,
} from "@/shelves/params";

import router from "@/plugins/router";

let _instance;

export const getAuthInstance = () => _instance;

const errString = async (e) => {
  const stack = await StackTrace.fromError(e);
  const errString = `
Name: ${e.name}
Message: ${e.message}
Stack:
${stack.map((e) => e.toString()).join("\n")}
`;
  return errString;
};

export const createAuthInstance = ({ store }) => {
  if (_instance) return _instance;

  _instance = new Vue({
    data() {
      return {
        isAuthenticated: false, // this can't just be a method, because we watch on it
        logoutInProgress: false,
        appSetupInProgress: true,
        authPluginBeingCreated: true,
        idaas: null, // "idaas" == Identity-as-a-Service, i.e. Auth0 or AD
        // values: "ad" or "auth0" or "demo"
        //  this should only be modified by setIDaaS, so that it moves in lock step with localStorage
      };
    },
    methods: {
      /*
                        888    8888888 8888888b.                     .d8888b.  
                        888      888   888  "Y88b                   d88P  Y88b 
                        888      888   888    888                   Y88b.      
      .d8888b   .d88b.  888888   888   888    888  8888b.   8888b.   "Y888b.   
      88K      d8P  Y8b 888      888   888    888     "88b     "88b     "Y88b. 
      "Y8888b. 88888888 888      888   888    888 .d888888 .d888888       "888 
           X88 Y8b.     Y88b.    888   888  .d88P 888  888 888  888 Y88b  d88P 
      88888P'  "Y8888   "Y888 8888888 8888888P"  "Y888888 "Y888888  "Y8888P"  
                                                                              
                                                                              
                                                                              
      */
      setIDaaS(t) {
        if (t == null) {
          localStorage.removeItem("idaas");
        } else {
          this.idaas = t;
          localStorage.setItem("idaas", t);
        }
      },
      /*
      888                   d8b                 d8888          888    888       .d8888b.  
      888                   Y8P                d88888          888    888      d88P  Y88b 
      888                                     d88P888          888    888      888    888 
      888  .d88b.   .d88b.  888 88888b.      d88P 888 888  888 888888 88888b.  888    888 
      888 d88""88b d88P"88b 888 888 "88b    d88P  888 888  888 888    888 "88b 888    888 
      888 888  888 888  888 888 888  888   d88P   888 888  888 888    888  888 888    888 
      888 Y88..88P Y88b 888 888 888  888  d8888888888 Y88b 888 Y88b.  888  888 Y88b  d88P 
      888  "Y88P"   "Y88888 888 888  888 d88P     888  "Y88888  "Y888 888  888  "Y8888P"  
                        888                                                               
                  Y8b d88P                                                               
                    "Y88P"                                                                
      */
      async loginAuth0(o) {
        store.commit("reset");
        this.setIDaaS("auth0");
        try {
          await (await auth0Client()).loginWithRedirect(o);
        } catch (e) {
          this.logout({
            exception: e,
            bugTitle: "Login Error",
            bugDetails: `Error with loginAuth0()`,
          });
        }
      },
      /*
      888                   d8b                 d8888          888    888       .d8888b.  8888888b.                  888     .d8888b.  
      888                   Y8P                d88888          888    888      d88P  Y88b 888   Y88b                 888    d88P  Y88b 
      888                                     d88P888          888    888      888    888 888    888                 888           888 
      888  .d88b.   .d88b.  888 88888b.      d88P 888 888  888 888888 88888b.  888    888 888   d88P 8888b.  888d888 888888      .d88P 
      888 d88""88b d88P"88b 888 888 "88b    d88P  888 888  888 888    888 "88b 888    888 8888888P"     "88b 888P"   888     .od888P"  
      888 888  888 888  888 888 888  888   d88P   888 888  888 888    888  888 888    888 888       .d888888 888     888    d88P"      
      888 Y88..88P Y88b 888 888 888  888  d8888888888 Y88b 888 Y88b.  888  888 Y88b  d88P 888       888  888 888     Y88b.  888"       
      888  "Y88P"   "Y88888 888 888  888 d88P     888  "Y88888  "Y888 888  888  "Y8888P"  888       "Y888888 888      "Y888 888888888  
                        888                                                                                                            
                  Y8b d88P                                                                                                            
                    "Y88P"                                                                                                             
      */
      async loginAuth0Part2() {
        // when we log in, we don't want to look at local storage and
        // see a stale value for "lastActive", so it needs to be cleared on login
        localStorage.removeItem("lastActive");
        try {
          /*
            Two things happen here:
            1. Exchange Authorization Code for Tokens: The Auth0 client exchanges the 
                authorization code (received in the redirect URI query parameters) 
                for tokens (ID token and access token).
            2. Writing Tokens to Local Storage: After successfully exchanging the 
                authorization code for tokens, @auth0/auth0-spa-js writes these 
                tokens to the specified cache location, which in your case is local storage.
                This step is internal to the handleRedirectCallback method.
            
            ID Token vs Access Token:
            - The ID token contains information about the user
              (this is what gets called with auth0client.getUser() or auth0client.isAuthenticated() )
            - The access token is used to authorize API requests
              (this is what gets fetched from auth0client.getTokenSilently() )
          */
          const { appState } = await (
            await auth0Client()
          ).handleRedirectCallback();
          return appState;
        } catch (e) {
          this.logout({
            excpetion: e,
            bugTitle: "Login Error",
            bugDetails: `Error with loginAuth0Part2()`,
          });
        }
      },
      /*
      888                   d8b                 d8888 8888888b.  
      888                   Y8P                d88888 888  "Y88b 
      888                                     d88P888 888    888 
      888  .d88b.   .d88b.  888 88888b.      d88P 888 888    888 
      888 d88""88b d88P"88b 888 888 "88b    d88P  888 888    888 
      888 888  888 888  888 888 888  888   d88P   888 888    888 
      888 Y88..88P Y88b 888 888 888  888  d8888888888 888  .d88P 
      888  "Y88P"   "Y88888 888 888  888 d88P     888 8888888P"  
                        888                                      
                  Y8b d88P                                      
                    "Y88P"                                       
      */
      async loginAD(redirectPath) {
        const handleError = async (e) => {
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/891a334291b1680a860fa606945283767bcf8815/lib/msal-browser/src/error/BrowserAuthError.ts
          if (
            (e instanceof Msal.BrowserAuthError &&
              (e.errorCode == "user_cancelled" ||
                e.errorCode == "interaction_in_progress")) ||
            (e instanceof Msal.ClientAuthError &&
              (e.errorCode == "user_cancelled" ||
                e.errorCode == "login_progress_error"))
          ) {
            // do nothing - normal errors in the course of closing popup window
            return;
          }
          if (
            (e instanceof Msal.BrowserAuthError &&
              (e.errorCode == "popup_window_error" ||
                e.errorCode == "no_network_connectivity")) ||
            (e instanceof Msal.ClientAuthError &&
              e.errorCode == "popup_window_error")
          ) {
            // don't report bug, but do provide some feedback
            redToast(this, e.message);
            return;
          }
          this.$buefy.modal.open({
            parent: this,
            component: ReportBugModalVue,
            hasModalCard: true,
            trapFocus: true,
            props: {
              autogenContent: await errString(e),
              autogenTitle: "AD Login Error",
            },
          });

          await this.logout();
        };

        store.commit("reset");
        this.setIDaaS("ad");
        // when we log in, we don't want to look at local storage and
        // see a stale value for "lastActive", so it needs to be cleared on login
        localStorage.removeItem("lastActive");

        this.appSetupInProgress = true;
        try {
          await adClient().loginPopup();
          store.commit("setIsLoading", true);
          router.push("/loading");
          this.isAuthenticated = true;
        } catch (e) {
          await handleError(e);
          this.appSetupInProgress = false;
          return;
        }

        try {
          await this.setupApp(await apiUserObj(this.idaas), { redirectPath });
        } catch {
          this.gotoDiagnostics();
        } finally {
          this.appSetupInProgress = false;
        }
      },
      /*
      888                   d8b          8888888b.                                  
      888                   Y8P          888  "Y88b                                 
      888                                888    888                                 
      888  .d88b.   .d88b.  888 88888b.  888    888  .d88b.  88888b.d88b.   .d88b.  
      888 d88""88b d88P"88b 888 888 "88b 888    888 d8P  Y8b 888 "888 "88b d88""88b 
      888 888  888 888  888 888 888  888 888    888 88888888 888  888  888 888  888 
      888 Y88..88P Y88b 888 888 888  888 888  .d88P Y8b.     888  888  888 Y88..88P 
      888  "Y88P"   "Y88888 888 888  888 8888888P"   "Y8888  888  888  888  "Y88P"  
                        888                                                         
                  Y8b d88P                                                         
                    "Y88P"                                                          
      */
      async loginDemo() {
        // when we log in, we don't want to look at local storage and
        // see a stale value for "lastActive", so it needs to be cleared on login
        localStorage.removeItem("lastActive");
        store.commit("reset");
        this.setIDaaS("demo");
        this.isAuthenticated = true;

        demoClient().login();
        return await apiUserObj(this.idaas);
      },
      /*
      d8b      888                            888     888                           
      Y8P      888                            888     888                           
               888                            888     888                           
      888  .d88888  8888b.   8888b.  .d8888b  888     888 .d8888b   .d88b.  888d888 
      888 d88" 888     "88b     "88b 88K      888     888 88K      d8P  Y8b 888P"   
      888 888  888 .d888888 .d888888 "Y8888b. 888     888 "Y8888b. 88888888 888     
      888 Y88b 888 888  888 888  888      X88 Y88b. .d88P      X88 Y8b.     888     
      888  "Y88888 "Y888888 "Y888888  88888P'  "Y88888P"   88888P'  "Y8888  888     
                                                                                    
                                                                                    
                                                                                    
      */
      async idaasUser() {
        try {
          if (this.idaas == "auth0") {
            // This information is derived from an ID token, not an access token
            return await (await auth0Client()).getUser();
          } else if (this.idaas == "ad") {
            return adClient().getUser();
          } else {
            return { error: `idaasUser failed -- idaas == ${this.idaas}` };
          }
        } catch (e) {
          return { error: "idaasUser failed", exception: e };
        }
      },
      /*
                        888  88888888888       888                         .d8888b.  d8b 888                   888    888          
                        888      888           888                        d88P  Y88b Y8P 888                   888    888          
                        888      888           888                        Y88b.          888                   888    888          
      .d88b.   .d88b.  888888   888   .d88b.  888  888  .d88b.  88888b.   "Y888b.   888 888  .d88b.  88888b.  888888 888 888  888 
      d88P"88b d8P  Y8b 888      888  d88""88b 888 .88P d8P  Y8b 888 "88b     "Y88b. 888 888 d8P  Y8b 888 "88b 888    888 888  888 
      888  888 88888888 888      888  888  888 888888K  88888888 888  888       "888 888 888 88888888 888  888 888    888 888  888 
      Y88b 888 Y8b.     Y88b.    888  Y88..88P 888 "88b Y8b.     888  888 Y88b  d88P 888 888 Y8b.     888  888 Y88b.  888 Y88b 888 
      "Y88888  "Y8888   "Y888   888   "Y88P"  888  888  "Y8888  888  888  "Y8888P"  888 888  "Y8888  888  888  "Y888 888  "Y88888 
          888                                                                                                                 888 
      Y8b d88P                                                                                                            Y8b d88P 
      "Y88P"                                                                                                              "Y88P"  
      */
      async getTokenSilently(o, returnNullOnException = false) {
        console.log("[auth.js] getTokenSilently called");
        try {
          if (this.idaas === "auth0") {
            const client = await auth0Client();
            /*
              This gets an Access Token, not an ID Token.

              "Getting a token silently" is different than a refresh token
              Silent authentication relies on the user having an active session 
              with the authorization server, while refresh tokens are a standalone 
              mechanism that doesn't depend on a user's session with the authorization server.

              Typically renewal happens when the auth0 client "realizes"
              that the cached access token is expired. As long as the user
              still has an active session with Auth0, it can get a new token

              Token expiration is different than user session expiration. Token
              expiration just means, "time to ask Auth0 for a new token". User session
              expiration means, "ask the user to log in again".

              The token expiration is 24 hours. This is configured in the Auth0 portal
              under APIs > Flank API > Token Settings > Token Expiration

              The user session expiration is 3 days of inactivity.
              Some login of some sort is required once every 30 days.
              These are configured in Auth0 > Settings > Advanced > Login Session Management

              SUMMARY OF EXPIRATIONS:
              - Expiration, regardless of activity
                - Auth0 fixed expiration (30 days)
                - (When 3rd party cookies are disabled) - ID Token Expiration (10 hours)
              - Inactivity Expiration
                - User Session on Auth0 side (3 days)
                - Flank idle timer in App.vue (48 hours or 1 hour, depending on Auth0/AD, as of 2023/12/11)
              - Implementation detail:
                - Access Token Expiration (24 hours) - This is an implementation detail
                  because getTokenSilently() will automatically renew the token
                - (When 3rd party cookies are enable) - ID Token Expiration (10 hours)

              FUTURE:
              We may want to move to a refresh token scheme. This is because browsers
              block 3rd party cookies by default now, and that inhibits the silent
              token retrieval scheme. Right now, getTokenSilently() stops working as soon as the 
              ID token expires, so it's as if there is a 10 hour logout timer regardless of activity
              https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari

              UPDATE:
              I confirmed this by turning off the 3rd party cookie blocking on Brave, and it behaved as expected
            */
            return await client.getTokenSilently(o);
          } else if (this.idaas === "ad") {
            return await timeout(adClient().getTokenSilentlyOrPop(), 10000);
          } else if (this.idaas === "demo") {
            return demoClient().getUser();
          } else {
            if (returnNullOnException) {
              return null;
            } else {
              console.log(
                "[auth.js] getTokenSilently() idaas invalid, calling virtualLogout()"
              );
              this.virtualLogout();
            }
          }
        } catch (e) {
          // An area for further improvement here would be to parse the exact message
          // and to differentiate between "login required" (just logout) and a "network error" (retry)
          if (returnNullOnException) {
            return null;
          } else {
            // if getTokenSilently fails, set isAuthenticated to false
            // and do all the other localStorage cleanup that needs to happen
            console.log(e);
            console.log(
              "[auth.js] getTokenSilently exception, calling virtualLogout()"
            );
            this.virtualLogout();
          }
        }
      },
      /*
      d8b           .d8888b.                    888                    888 8888888     888 88888888888       888                       888     888         888 d8b      888 
      Y8P          d88P  Y88b                   888                    888   888       888     888           888                       888     888         888 Y8P      888 
                  888    888                   888                    888   888       888     888           888                       888     888         888          888 
      888 .d8888b  888         8888b.   .d8888b 88888b.   .d88b.   .d88888   888   .d88888     888   .d88b.  888  888  .d88b.  88888b. Y88b   d88P 8888b.  888 888  .d88888 
      888 88K      888            "88b d88P"    888 "88b d8P  Y8b d88" 888   888  d88" 888     888  d88""88b 888 .88P d8P  Y8b 888 "88b Y88b d88P     "88b 888 888 d88" 888 
      888 "Y8888b. 888    888 .d888888 888      888  888 88888888 888  888   888  888  888     888  888  888 888888K  88888888 888  888  Y88o88P  .d888888 888 888 888  888 
      888      X88 Y88b  d88P 888  888 Y88b.    888  888 Y8b.     Y88b 888   888  Y88b 888     888  Y88..88P 888 "88b Y8b.     888  888   Y888P   888  888 888 888 Y88b 888 
      888  88888P'  "Y8888P"  "Y888888  "Y8888P 888  888  "Y8888   "Y88888 8888888 "Y88888     888   "Y88P"  888  888  "Y8888  888  888    Y8P    "Y888888 888 888  "Y88888 
                                                                                                                                                                            
                                                                                                                                                                            
                                                                                                                                                                            
*/
      async _isCachedIdTokenValid() {
        /*
          This is super confusing but isAuthenticated() does NOT
          check if the user has an active user session with Auth0.
          That's why I wrapped everything in a method called 
          _isCachedIdTokenValid, because that's really waht isAuthenticated() does.

          If the ID token has a lower expiration than the user session,
          the ID token could expire and this method could return false,
          but the user session could still be active.
          
          Basically, the difference is that this looks at the ID token
          and getTokenSilently() looks at the access token.

          Why aren't the two syncronized? For our purposes, we want them to 
          be. There is no situation where we want the user to be logged into
          the SPA but not authorized to make API calls. I think this design
          is really intended for complex SSO apps and banking apps and shit like that
        */
        try {
          if (this.idaas == "auth0") {
            const client = await auth0Client();
            return await client.isAuthenticated();
          } else if (this.idaas == "ad") {
            return adClient().isAuthenticated();
          } else if (this.idaas == "demo") {
            return demoClient().isAuthenticated();
          } else {
            return false;
          }
        } catch (e) {
          return false;
          // console.log(e);
          // console.log("isCachedIdTokenValid failed => virtual logout");
          // this.virtualLogout();
        }
      },
      /*
        d8b          888     888                           .d8888b.                             d8b                   8888888b.                   888 888                 d8888          888    d8b                   
        Y8P          888     888                          d88P  Y88b                            Y8P                   888   Y88b                  888 888                d88888          888    Y8P                   
                     888     888                          Y88b.                                                       888    888                  888 888               d88P888          888                          
        888 .d8888b  888     888 .d8888b   .d88b.  888d888 "Y888b.    .d88b.  .d8888b  .d8888b  888  .d88b.  88888b.  888   d88P .d88b.   8888b.  888 888 888  888     d88P 888  .d8888b 888888 888 888  888  .d88b.  
        888 88K      888     888 88K      d8P  Y8b 888P"      "Y88b. d8P  Y8b 88K      88K      888 d88""88b 888 "88b 8888888P" d8P  Y8b     "88b 888 888 888  888    d88P  888 d88P"    888    888 888  888 d8P  Y8b 
        888 "Y8888b. 888     888 "Y8888b. 88888888 888          "888 88888888 "Y8888b. "Y8888b. 888 888  888 888  888 888 T88b  88888888 .d888888 888 888 888  888   d88P   888 888      888    888 Y88  88P 88888888 
        888      X88 Y88b. .d88P      X88 Y8b.     888    Y88b  d88P Y8b.          X88      X88 888 Y88..88P 888  888 888  T88b Y8b.     888  888 888 888 Y88b 888  d8888888888 Y88b.    Y88b.  888  Y8bd8P  Y8b.     
        888  88888P'  "Y88888P"   88888P'  "Y8888  888     "Y8888P"   "Y8888   88888P'  88888P' 888  "Y88P"  888  888 888   T88b "Y8888  "Y888888 888 888  "Y88888 d88P     888  "Y8888P  "Y888 888   Y88P    "Y8888  
                                                                                                                                                              888                                                    
                                                                                                                                                          Y8b d88P                                                    
                                                                                                                                                          "Y88P"                                                     
      */
      async isUserSessionReallyActive() {
        /*
          First check to see if the ID token is valid.
          If it is, then we know that the user session is active.
          If it's not, then we need to check the access token.
          If the access token is valid, then we know that the user session is active.

          This assumes that the ID token expiration is less than the user session expiration.

          In practice, getTokenSilently seems to fail after the ID token expires,
          possibly because the browser blocks 3rd party cookies. It seems that the 
          getTokenSilently() pattern may not be the recommended way anymore.
          https://auth0.com/docs/troubleshoot/authentication-issues/renew-tokens-when-using-safari

          UPDATE:
          I confirmed this by turning off the 3rd party cookie blocking on Brave, and it behaved as expected
        */
        const cachedIdTokenValid = await this._isCachedIdTokenValid();
        if (cachedIdTokenValid) {
          return true;
        }
        console.log("ID TOKEN NOT VALID, CHECKING ACCESS TOKEN");
        console.log(
          "[auth.js] isUserSessionReallyActive() calling getTokenSilently"
        );
        const token = await this.getTokenSilently({}, true);
        if (token === null) {
          console.log("ACCESS TOKEN NOT VALID");
          return false;
        } else {
          console.log("ACCESS TOKEN VALID");
          return true;
        }
      },
      /*
      888                                     888    
      888                                     888    
      888                                     888    
      888  .d88b.   .d88b.   .d88b.  888  888 888888 
      888 d88""88b d88P"88b d88""88b 888  888 888    
      888 888  888 888  888 888  888 888  888 888    
      888 Y88..88P Y88b 888 Y88..88P Y88b 888 Y88b.  
      888  "Y88P"   "Y88888  "Y88P"   "Y88888  "Y888 
                        888                          
                  Y8b d88P                          
                    "Y88P"                           
      */
      async logout({
        exception = null,
        bugTitle = "",
        bugDetails = "",
        idleLogout = false,
        initiatedByThisTab = true,
      } = {}) {
        this.logoutInProgress = true;
        console.log("LOGOUT");
        if (initiatedByThisTab) {
          if (this.idaas == "auth0") {
            await (await auth0Client()).logout({
              returnTo: window.location.origin,
            });
          } else if (this.idaas == "ad" && !idleLogout) {
            await adClient().logoutPopup();
            // this is because there is no way to programatically log
            // out of AD -- it requires a user to click a button
          } else if (this.idaas == "ad" && idleLogout) {
            localStorage.clear();
          }
          localStorage.setItem(
            "logoutInitiatedBySomeTab",
            new Date().getTime().toString()
          );
          store.commit("reset");

          // when we log in, we don't want to look at local storage and
          // see a stale value for "lastActive", so it needs to be cleared on logout
          localStorage.removeItem("lastActive");
          this.setIDaaS(null);
        }
        this.isAuthenticated = false;
        router.push("/"); // even though Auth0 / Msal will redirect to the
        // home page, there is a little lag, which this covers

        if (exception !== null) {
          localStorage.setItem(
            "exceptionCausingLogout",
            JSON.stringify({
              e: serializeError(exception),
              bugTitle: bugTitle,
              bugDetails: bugDetails,
            })
          );
        }
        this.logoutInProgress = false;
      },
      async virtualLogout({ bugTitle = "", initiatedByThisTab = true } = {}) {
        this.logoutInProgress = true;
        console.log("VIRTUAL LOGOUT");
        if (initiatedByThisTab) {
          /*
            this is a nuclear bomb that wipes out
            - vuex
            - any tokens stored by auth0 / msal
            - idaas
            - lastActive
          */
          localStorage.clear();
          localStorage.setItem(
            "virtualLogoutInitiatedBySomeTab",
            new Date().getTime().toString()
          );
        }
        this.isAuthenticated = false;
        if (bugTitle) {
          redToast(this, bugTitle, 7000);
        }
        this.logoutInProgress = false;
      },
      gotoDiagnostics(toast = "") {
        console.log("DIAGNOSTICS");
        router.push("/diagnostics");
        redToast(this, toast ?? `There was an error setting up Flank`, 7000);
      },
      /*
                        888                            d8888                   
                        888                           d88888                   
                        888                          d88P888                   
      .d8888b   .d88b.  888888 888  888 88888b.     d88P 888 88888b.  88888b.  
      88K      d8P  Y8b 888    888  888 888 "88b   d88P  888 888 "88b 888 "88b 
      "Y8888b. 88888888 888    888  888 888  888  d88P   888 888  888 888  888 
          X88 Y8b.     Y88b.  Y88b 888 888 d88P d8888888888 888 d88P 888 d88P 
      88888P'  "Y8888   "Y888  "Y88888 88888P" d88P     888 88888P"  88888P"  
                                        888                  888      888      
                                        888                  888      888      
                                        888                  888      888      
      */
      async setupApp(apiUserObj, appState) {
        try {
          // api issue => diagnostics
          // any other bug => bug report
          console.log("SETUP");
          let joinOrgToken = null;
          if (appState?.redirectPath) {
            const params = pathToQueryParams(appState.redirectPath, true);
            joinOrgToken = oget(params, "joinOrgToken");
          }

          store.commit("wormhole", {
            method: "pushLoadingText",
            params: { text: "Fetching your user info..." },
          });
          let flankUser;
          flankUser = await api.get("users/me");
          if (flankUser == null) {
            const createUserResp = await api.post(
              "users/me",
              { user: apiUserObj, joinOrgToken: joinOrgToken },
              {
                orgPath: false,
              }
            );
            if (createUserResp.success) {
              flankUser = createUserResp.user;
            } else {
              this.logout({
                exception: Error(),
                bugTitle:
                  createUserResp.message ??
                  "Unknown error related to creating user",
              });
              return;
            }
          }
          store.commit("setFlankUser", new FlankUser(flankUser));
          store.commit("setFlankEnvUserId", flankUser.userId ?? "");
          store.commit("setFlankEnvUserEmail", flankUser.email ?? "");
          store.commit(
            "setFlankEnvUserName",
            flankUser.name ?? flankUser.nickname ?? ""
          );

          if (joinOrgToken) {
            store.commit("wormhole", {
              method: "pushLoadingText",
              params: { text: "Joining org..." },
            });
            try {
              const joinOrgResp = await api.post(
                `users/${flankUser.userId}/join_org`,
                { joinOrgToken },
                { orgPath: false }
              );

              if (!joinOrgResp.success) {
                this.logout({
                  exception: Error(),
                  bugTitle: joinOrgResp.message,
                });
              } else if (joinOrgResp.message) {
                blueToast(this, joinOrgResp.message, 7000);
              }
            } catch (e) {
              console.log(e);
              this.logout({
                exception: e,
                bugTitle: "Unknown error related to joinOrgToken",
              });
              return;
            }
          }

          store.commit("wormhole", {
            method: "pushLoadingText",
            params: { text: "Loading your orgs..." },
          });
          let orgs;
          try {
            orgs = await api.get("users/me/orgs");
          } catch (e) {
            this.gotoDiagnostics();
            return;
          }

          const nonPendingOrgs = orgs.filter((o) => !o.isPendingToOrg);
          if (nonPendingOrgs.length == 0) {
            console.log("orgslength0");
            if (process.env.VUE_APP_FLANK_META_ENV == "private_ad") {
              console.log("private ad");
              // user who hasn't been sync'd yet
              router.push({
                name: "whoopsRoute",
                params: {
                  overrideMessage:
                    "You haven't been added to an organization yet. Talk to an admin and have them sync users from ActiveDirectory, in Flank. Then you should have access.",
                },
              });
              return;
            } else {
              store.commit("wormhole", {
                method: "pushLoadingText",
                params: { text: "Setting up your first org..." },
              });
              await api.post("users/me/orgs", {
                orgName: `${
                  flankUser.name ?? flankUser.nickname ?? flankUser.email
                }'s Org`,
              });
              orgs = await api.get("users/me/orgs");
            }
          }
          store.commit("setOrgs", orgs);
          if (appState?.redirectPath) {
            const possibleOrgId = lfirst(
              strim(appState.redirectPath, "/").split("/")
            ); // "possible" becuase url could be something like flank.cloud/orgs or
            // it could be a public kit or a cross-org shared kit that user is navigating to,
            // but isn't part of the org
            if (orgs.some((o) => o.orgId == possibleOrgId)) {
              store.commit("setActiveOrgById", possibleOrgId);
            } else {
              store.commit("setActiveOrgById", orgs[0].orgId);
            }
          } else {
            store.commit("setActiveOrgById", orgs[0].orgId);
          }
          store.commit("wormhole", {
            method: "pushLoadingText",
            params: { text: "Running startup commands..." },
          });
          try {
            // TODO - make this whole block async
            let runOnStartupCommands = await api.get("skinny-kits", {
              orgPath: true,
              query: {
                filter: ["anchor_run_on_startup:True"],
              },
            });
            console.log("runOnStartupCommands", runOnStartupCommands);
            const promises = runOnStartupCommands.map(async (command) => {
              console.log(command);
              if (store.state.activeOrg.role === "admin") {
                blueToast(this, "Setting env variables...");
              }
              const result = await api.post(
                `run-sync`,
                {
                  olId: command.anchorOl.olId,
                  kitId: command.kitId,
                  params: runramsToStr(
                    interpolateRunrams(
                      strToRunrams(command.anchorOl.runOnStartupValues),
                      store.state.flankEnv
                    )
                  ),
                  isStartup: true,
                },
                { orgPath: false }
              );

              console.log("result", result);
              // TODO - this is fragile
              const resp = await transformPayload(
                result,
                "json.multiset.rowwise"
              );

              console.log("startup resp", resp);
              if (isArray2(resp) && resp.length === 1) {
                store.commit("setFlankEnvOtherVars", resp[0]);
              } else {
                redToast(
                  this,
                  "Error running startup command, resp not length 1"
                );
              }
            });

            await Promise.all(promises);
            if (store.state.activeOrg.role === "admin") {
              greenToast(this, store.state.flankEnv);
            }
          } catch (e) {
            console.log("error running startup command");
            console.log(e);
            redToast(this, "Error running startup command - " + e, 10000);
            if (store.state.activeOrg.role === "admin") {
              blueToast(this, store.state.flankEnv);
            }
          }
          if (appState?.redirectPath) {
            const qs = queryParamsToQueryString(
              odrop(pathToQueryParams(appState.redirectPath, true), [
                "joinOrgToken",
              ])
            );
            router.replace(fullPathToPath(appState.redirectPath) + "?" + qs);
          } else {
            router.replace({
              name: "legosHQRoute",
              params: { orgId: orgs[0].orgId },
            });
          }
        } catch (e) {
          console.log(e);
          this.logout({ exception: e, bugTitle: "Error setting up app" });
        }
      },
      getTimeoutDuration() {
        // return 15000;
        return process.env.VUE_APP_FLANK_META_ENV === "private_ad"
          ? 2 * 60 * 60 * 1000
          : 48 * 60 * 60 * 1000;
      },
    },
    /*
                                      888                 888 
                                      888                 888 
                                      888                 888 
    .d8888b 888d888 .d88b.   8888b.  888888 .d88b.   .d88888 
    d88P"    888P"  d8P  Y8b     "88b 888   d8P  Y8b d88" 888 
    888      888    88888888 .d888888 888   88888888 888  888 
    Y88b.    888    Y8b.     888  888 Y88b. Y8b.     Y88b 888 
    "Y8888P 888     "Y8888  "Y888888  "Y888 "Y8888   "Y88888 
                                                              
                                                              
                                                              
    */
    async created() {
      // created() gets called whenever
      // 1. Auth0 navigates back to the app
      // 2. When the app gets reinstantiated
      //    a. Initial landing
      //    b. Redirect to home page after logout
      //    c. Page refresh
      //    d. New tab
      // 3. After Auth0/AD log out and return back to the home page
      console.log("AUTH CREATED");

      this.idaas = localStorage.getItem("idaas");

      if (redirectingFromAuth0Splash()) {
        // #1
        store.commit("setIsLoading", true);
        store.commit("wormhole", {
          method: "pushLoadingText",
          params: { text: "Checking authentication" },
        });
        let appState = await this.loginAuth0Part2();
        // Might be overkill to check both ID token and user session possibly,
        // but this should always evaluate to true anyway... so I think
        // it'll hit the ID token in cache and return true without making
        // a network call
        this.isAuthenticated = await this.isUserSessionReallyActive();

        await this.setupApp(await apiUserObj(this.idaas), appState);
        this.appSetupInProgress = false;
        this.authPluginBeingCreated = false;
      } else {
        // #2 #3
        // Checking full user session (not just ID token) because
        // what if we refresh the page AFTER id token has expired but
        // BEFORE user session has expired?
        console.log("[auth.js] created() calling isUserSessionReallyActive()");
        const isUserSessionReallyActive = await this.isUserSessionReallyActive();
        console.log(
          "[auth.js] created() isUserSessionReallyActive: " +
            isUserSessionReallyActive
        );
        if (isUserSessionReallyActive) {
          const lastActive = localStorage.getItem("lastActive");
          const now = new Date().getTime();

          if (
            lastActive &&
            now - parseInt(lastActive) > this.getTimeoutDuration()
          ) {
            // Force logout due to inactivity
            console.log(
              "[auth.js] created(), inactivity exceeded, now: " +
                now +
                ", lastActive: " +
                lastActive +
                ", timeoutDuration: " +
                this.getTimeoutDuration()
            );
            await this.virtualLogout();
            // redundant w/virtualLogout... just for readability
            this.isAuthenticated = false;
          } else {
            this.isAuthenticated = true;
          }
        } else {
          this.isAuthenticated = false;
        }
        if (this.isAuthenticated) {
          const queryParams = pathToQueryParams(windowToFullPath(window), true);
          if ("joinOrgToken" in queryParams) {
            await this.setupApp(await apiUserObj(this.idass), {
              redirectPath: windowToFullPath(window),
            });
          }
          this.appSetupInProgress = false;
          this.authPluginBeingCreated = false;
          return;
        }

        const queryParams = pathToQueryParams(windowToFullPath(window), true);
        if ("goStraightToAuth0" in queryParams) {
          const qs = queryParamsToQueryString(
            odrop(queryParams, ["goStraightToAuth0"])
          );
          this.loginAuth0({
            appState: {
              redirectPath: fullPathToPath(windowToFullPath(window)) + "?" + qs,
            },
            screen_hint: "signup",
          });
          this.authPluginBeingCreated = false;
          this.appSetupInProgress = true;
          return;
        }

        // AM: I'm a little confused because appSetupInProgress gets set to false
        // when AD auth.js has loaded but hasn't logged in yet
        this.appSetupInProgress = false;
        this.authPluginBeingCreated = false;
        return;
      }
    },
  });

  return _instance;
};

export const AuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = createAuthInstance(options);
  },
};
