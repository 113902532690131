var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar-item',{attrs:{"tag":"div"}},[_c('b-dropdown',{attrs:{"role":"button","position":"is-bottom-left","append-to-body":"","aria-role":"menu"}},[_c('button',{attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"user-circle"}}),_c('b-icon',{attrs:{"icon":"caret-down"}})],1),_c('b-dropdown-item',{attrs:{"custom":"","aria-role":"menuitem"}},[_c('div',[_c('div',{staticClass:"tw-font-bold tw-text-xs"},[_vm._v(" "+_vm._s(_vm.flankUser.name)+" ")]),_c('div',{staticClass:"tw-text-xs tw-pb-3"},[_vm._v(" "+_vm._s(_vm.flankUser.email)+" ")])])]),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{ path: '/orgs' }}},[_vm._v(" All My Organizations ")])],1),_c('hr',{staticClass:"dropdown-divider"}),(_vm.activeOrg !== null && _vm.activeOrg.role === 'admin')?_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{
          name: 'credentialsRoute',
          params: { orgId: _vm.$store.state.activeOrg.orgId },
        }}},[_vm._v(" Credentials ")])],1):_vm._e(),(_vm.activeOrg !== null && _vm.activeOrg.role === 'admin')?_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{
          name: 'resourcesRoute',
          params: { orgId: _vm.$store.state.activeOrg.orgId },
        }}},[_vm._v(" Resources ")])],1):_vm._e(),(_vm.activeOrg !== null && _vm.activeOrg.role === 'admin')?_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{
          name: 'orgCronJobsRoute',
          params: { orgId: _vm.$store.state.activeOrg.orgId },
        }}},[_vm._v(" Scheduled Jobs ")])],1):_vm._e(),(_vm.activeOrg !== null && _vm.activeOrg.role === 'admin')?_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{
          name: 'orgDropdownsRoute',
          params: { orgId: _vm.$store.state.activeOrg.orgId },
        }}},[_vm._v(" Dropdowns ")])],1):_vm._e(),(_vm.activeOrg !== null)?_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{
          name: 'orgRoute',
          params: { orgId: _vm.$store.state.activeOrg.orgId },
        }}},[_vm._v(" Organization Users ")])],1):_vm._e(),_c('hr',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('a',{on:{"click":_vm.openBugReporter}},[_vm._v(" Report Bug ")])]),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":{ path: '/diagnostics' }}},[_vm._v(" Diagnostics ")])],1),_c('hr',{staticClass:"dropdown-divider",attrs:{"aria-role":"menuitem"}}),_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }