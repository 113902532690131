import {
  lfind,
  lmap2o,
  lreplace,
  lsort,
  ocopy,
  omerge,
  ovalueFilter,
} from "@/brains/flang";
import {
  ECronJobDecorationGET,
  ECronJobDecorationPATCHReq,
  ECronJobDecorationPOSTReq,
  ECronJobDecorationPOSTResp,
  ECronJobGET,
  ECronJobGET_CJD_CronJobDecorationInput,
  ECronJobGET_CronJobDecoration,
  ECronJobPATCHReq,
  ECronJobPOSTReq,
  ECronJobPOSTResp,
} from "@/types/api-schema";
import {
  ApiRequest,
  FlatSchemaElement,
  HttpMethod,
  KitRunSchemaElement,
} from "@/types/general-flank-types";
import { clearSchemaKitRunData, schemaToRunrams } from "./params";
import { bindFlatParamsToSchema } from "./params";

export type CronJobListPlanOptions = {
  greenToastOnSuccess?: string;
  redToastOnFailure?: string;
};
export type CronJobProcessed = ECronJobGET;

function _initCronJobProcessed(cronJob: ECronJobGET): CronJobProcessed {
  const processed = cronJob;
  processed.cronJobDecorations = processed.cronJobDecorations.map(
    (cjd: ECronJobGET_CronJobDecoration) => {
      const procCJD = cjd;
      procCJD.cronJobDecorationInputs = lsort(
        procCJD.cronJobDecorationInputs,
        (cjdInput: ECronJobGET_CJD_CronJobDecorationInput) =>
          cjdInput.legoInstanceInput.order
      );
      return procCJD;
    }
  );
  return processed;
}
function _initCronJobProcessedList(
  cronJobList: ECronJobGET[]
): CronJobProcessed[] {
  return cronJobList.map((c) => _initCronJobProcessed(c));
}
export class CronJobListPlan {
  cronJobList: CronJobProcessed[] | null;
  parallelRequests: ApiRequest[] | null;
  cronJobListRequest: ApiRequest | null;
  options: CronJobListPlanOptions;
  constructor({
    cronJobList = null,
    parallelRequests = null,
    cronJobListRequest = null,
    options = {},
  }: {
    cronJobList?: CronJobProcessed[] | null;
    parallelRequests?: ApiRequest[] | null;
    cronJobListRequest?: ApiRequest | null;
    options?: CronJobListPlanOptions;
  } = {}) {
    this.cronJobList = cronJobList;
    this.parallelRequests = parallelRequests;
    this.options = options;
    this.cronJobListRequest = cronJobListRequest;
  }
  addOptions(o: CronJobListPlanOptions) {
    this.options = omerge(this.options, o);
    return this;
  }
}
function _cronRunramSchemaToRunrams(
  runramSchema: FlatSchemaElement[],
  kitRunSchema: KitRunSchemaElement[],
  skipAllEmptyElements = false
) {
  const populatedInputs = ovalueFilter(
    lmap2o(
      runramSchema,
      (e) => e.key,
      (e) => e.value
    ),
    (v) => v !== ""
  ) as Record<string, string>;
  const clearedKitRunSchema = clearSchemaKitRunData(ocopy(kitRunSchema));
  const schemaWithPopulatedInputs = bindFlatParamsToSchema(
    clearedKitRunSchema,
    populatedInputs,
    { skipLockedElements: skipAllEmptyElements }
  );
  const runrams = schemaToRunrams(
    schemaWithPopulatedInputs,
    {},
    { skipAllEmptyElements }
  );
  // console.log("populatedInputs", populatedInputs);
  // console.log("clearedKitRunSchema", clearedKitRunSchema);
  // console.log("schemaWithPopulatedInputs", schemaWithPopulatedInputs);
  // console.log("runrams", runrams);
  return runrams;
}
//                    8888888b.  888
//                    888   Y88b 888
//                    888    888 888
//                    888   d88P 888  8888b.  88888b.  .d8888b
//                    8888888P"  888     "88b 888 "88b 88K
// 888888 888888      888        888 .d888888 888  888 "Y8888b.      888888 888888
//                    888        888 888  888 888  888      X88
//                    888        888 "Y888888 888  888  88888P'

//  .d8888b.                           888
// d88P  Y88b                          888
// 888    888                          888
// 888        888d888 .d88b.   8888b.  888888 .d88b.
// 888        888P"  d8P  Y8b     "88b 888   d8P  Y8b
// 888    888 888    88888888 .d888888 888   88888888
// Y88b  d88P 888    Y8b.     888  888 Y88b. Y8b.
//  "Y8888P"  888     "Y8888  "Y888888  "Y888 "Y8888

function _planToCreateCronJob(
  kitId: number,
  body: ECronJobPOSTReq,
  fullOrgList: boolean
): CronJobListPlan {
  const request = {
    method: HttpMethod.POST,
    path: `kits/${kitId}/cron-jobs`,
    options: { orgPath: true },
    body: body,
    callback: (postResp: ECronJobPOSTResp) => {
      return {
        method: HttpMethod.GET,
        path: fullOrgList ? `cron-jobs` : `kits/${kitId}/cron-jobs`,
        options: { orgPath: true },
        callback: (resp: ECronJobGET[]) => {
          return {
            cronJobList: _initCronJobProcessedList(resp),
            newCronJobId: postResp.cronJobId,
          };
        },
      };
    },
  };
  return new CronJobListPlan({
    cronJobListRequest: request,
  });
}
// 8888888b.           888          888
// 888  "Y88b          888          888
// 888    888          888          888
// 888    888  .d88b.  888  .d88b.  888888 .d88b.
// 888    888 d8P  Y8b 888 d8P  Y8b 888   d8P  Y8b
// 888    888 88888888 888 88888888 888   88888888
// 888  .d88P Y8b.     888 Y8b.     Y88b. Y8b.
// 8888888P"   "Y8888  888  "Y8888   "Y888 "Y8888

function _planToDeleteCronJob(
  cjl: CronJobProcessed[],
  cronJobId: number,
  kitId: number
): CronJobListPlan {
  const cjlCopy = ocopy(cjl);
  const request = {
    method: HttpMethod.DELETE,
    path: `kits/${kitId}/cron-jobs/${cronJobId}`,
    options: { orgPath: true },
    callback: (resp: ECronJobGET) => {
      return {
        // soft delete
        cronJobList: lreplace(
          cjlCopy,
          _initCronJobProcessed(resp),
          (c) => c.cronJobId === cronJobId
        ),
      };
    },
  };
  return new CronJobListPlan({
    cronJobListRequest: request,
  });
}

// 888     888               888          888
// 888     888               888          888
// 888     888               888          888
// 888     888 88888b.   .d88888  8888b.  888888 .d88b.
// 888     888 888 "88b d88" 888     "88b 888   d8P  Y8b
// 888     888 888  888 888  888 .d888888 888   88888888
// Y88b. .d88P 888 d88P Y88b 888 888  888 Y88b. Y8b.
//  "Y88888P"  88888P"   "Y88888 "Y888888  "Y888 "Y8888
//             888
//             888
//             888
function _planToUpdateCronJob(
  cjl: CronJobProcessed[],
  kitId: number,
  cronJobId: number,
  body: ECronJobPATCHReq
): CronJobListPlan {
  const request = {
    method: HttpMethod.PATCH,
    path: `kits/${kitId}/cron-jobs/${cronJobId}`,
    options: { orgPath: true },
    body: body,
    callback: (resp: ECronJobGET) => {
      return {
        cronJobList: lreplace(
          cjl,
          _initCronJobProcessed(resp),
          (c) => c.cronJobId === cronJobId
        ),
      };
    },
  };
  return new CronJobListPlan({
    cronJobListRequest: request,
  });
}

// 8888888b.           .d888                          888           888      d8b          888
// 888   Y88b         d88P"                           888           888      Y8P          888
// 888    888         888                             888           888                   888
// 888   d88P .d88b.  888888 888d888 .d88b.  .d8888b  88888b.       888      888 .d8888b  888888
// 8888888P" d8P  Y8b 888    888P"  d8P  Y8b 88K      888 "88b      888      888 88K      888
// 888 T88b  88888888 888    888    88888888 "Y8888b. 888  888      888      888 "Y8888b. 888
// 888  T88b Y8b.     888    888    Y8b.          X88 888  888      888      888      X88 Y88b.
// 888   T88b "Y8888  888    888     "Y8888   88888P' 888  888      88888888 888  88888P'  "Y888

function _planToRefreshKitCronJobList(kitId: number): CronJobListPlan {
  return new CronJobListPlan({
    cronJobListRequest: {
      method: HttpMethod.GET,
      path: `kits/${kitId}/cron-jobs`,
      options: { orgPath: true },
      callback: (resp: ECronJobGET[]) => {
        return { cronJobList: _initCronJobProcessedList(resp) };
      },
    },
  });
}
function _planToRefreshOrgCronJobList(): CronJobListPlan {
  return new CronJobListPlan({
    cronJobListRequest: {
      method: HttpMethod.GET,
      path: `cron-jobs`,
      options: { orgPath: true },
      callback: (resp: ECronJobGET[]) => {
        return { cronJobList: _initCronJobProcessedList(resp) };
      },
    },
  });
}
// 8888888b.           888          888                 8888888b.                                            888    d8b
// 888  "Y88b          888          888                 888  "Y88b                                           888    Y8P
// 888    888          888          888                 888    888                                           888
// 888    888  .d88b.  888  .d88b.  888888 .d88b.       888    888  .d88b.   .d8888b .d88b.  888d888 8888b.  888888 888  .d88b.  88888b.
// 888    888 d8P  Y8b 888 d8P  Y8b 888   d8P  Y8b      888    888 d8P  Y8b d88P"   d88""88b 888P"      "88b 888    888 d88""88b 888 "88b
// 888    888 88888888 888 88888888 888   88888888      888    888 88888888 888     888  888 888    .d888888 888    888 888  888 888  888
// 888  .d88P Y8b.     888 Y8b.     Y88b. Y8b.          888  .d88P Y8b.     Y88b.   Y88..88P 888    888  888 Y88b.  888 Y88..88P 888  888
// 8888888P"   "Y8888  888  "Y8888   "Y888 "Y8888       8888888P"   "Y8888   "Y8888P "Y88P"  888    "Y888888  "Y888 888  "Y88P"  888  888
function _planToDeleteCronJobDecoration(
  cjl: CronJobProcessed[],
  kitId: number,
  cronJobId: number,
  cronJobDecorationId: number
): CronJobListPlan {
  const cjlCopy = ocopy(cjl);
  const parallelRequest = {
    method: HttpMethod.DELETE,
    path: `kits/${kitId}/cron-jobs/${cronJobId}/decorations/${cronJobDecorationId}`,
    options: { orgPath: true },
  };
  const cronJobRef = lfind(
    cjlCopy,
    (c: CronJobProcessed) => c.cronJobId === cronJobId
  );
  if (!cronJobRef) {
    throw new Error(`Cron job ${cronJobId} not found`);
  }
  // hard delete
  cronJobRef.cronJobDecorations = cronJobRef.cronJobDecorations.filter(
    (d: ECronJobGET_CronJobDecoration) =>
      d.cronJobDecorationId !== cronJobDecorationId
  );
  return new CronJobListPlan({
    cronJobList: cjlCopy,
    parallelRequests: [parallelRequest],
  });
}
//  .d8888b.                           888                 8888888b.                                            888    d8b
// d88P  Y88b                          888                 888  "Y88b                                           888    Y8P
// 888    888                          888                 888    888                                           888
// 888        888d888 .d88b.   8888b.  888888 .d88b.       888    888  .d88b.   .d8888b .d88b.  888d888 8888b.  888888 888  .d88b.  88888b.
// 888        888P"  d8P  Y8b     "88b 888   d8P  Y8b      888    888 d8P  Y8b d88P"   d88""88b 888P"      "88b 888    888 d88""88b 888 "88b
// 888    888 888    88888888 .d888888 888   88888888      888    888 88888888 888     888  888 888    .d888888 888    888 888  888 888  888
// Y88b  d88P 888    Y8b.     888  888 Y88b. Y8b.          888  .d88P Y8b.     Y88b.   Y88..88P 888    888  888 Y88b.  888 Y88..88P 888  888
//  "Y8888P"  888     "Y8888  "Y888888  "Y888 "Y8888       8888888P"   "Y8888   "Y8888P "Y88P"  888    "Y888888  "Y888 888  "Y88P"  888  888

function _planToCreateCronJobDecoration(
  kitId: number,
  cronJobId: number,
  body: ECronJobDecorationPOSTReq,
  fullOrgList: boolean
): CronJobListPlan {
  return new CronJobListPlan({
    cronJobListRequest: {
      method: HttpMethod.POST,
      path: `kits/${kitId}/cron-jobs/${cronJobId}/decorations`,
      options: { orgPath: true },
      body: body,
      callback: (resp: ECronJobDecorationPOSTResp) => {
        return fullOrgList
          ? _planToRefreshOrgCronJobList().cronJobListRequest
          : _planToRefreshKitCronJobList(kitId).cronJobListRequest;
      },
    },
  });
}
// 888     888               888          888                 8888888b.                                            888    d8b
// 888     888               888          888                 888  "Y88b                                           888    Y8P
// 888     888               888          888                 888    888                                           888
// 888     888 88888b.   .d88888  8888b.  888888 .d88b.       888    888  .d88b.   .d8888b .d88b.  888d888 8888b.  888888 888  .d88b.  88888b.
// 888     888 888 "88b d88" 888     "88b 888   d8P  Y8b      888    888 d8P  Y8b d88P"   d88""88b 888P"      "88b 888    888 d88""88b 888 "88b
// 888     888 888  888 888  888 .d888888 888   88888888      888    888 88888888 888     888  888 888    .d888888 888    888 888  888 888  888
// Y88b. .d88P 888 d88P Y88b 888 888  888 Y88b. Y8b.          888  .d88P Y8b.     Y88b.   Y88..88P 888    888  888 Y88b.  888 Y88..88P 888  888
//  "Y88888P"  88888P"   "Y88888 "Y888888  "Y888 "Y8888       8888888P"   "Y8888   "Y8888P "Y88P"  888    "Y888888  "Y888 888  "Y88P"  888  888
//             888
//             888
//             888
function _planToUpdateCronJobDecoration(
  kitId: number,
  cronJobId: number,
  cronJobDecorationId: number,
  body: ECronJobDecorationPATCHReq,
  fullOrgList: boolean
): CronJobListPlan {
  return new CronJobListPlan({
    cronJobListRequest: {
      method: HttpMethod.PATCH,
      path: `kits/${kitId}/cron-jobs/${cronJobId}/decorations/${cronJobDecorationId}`,
      options: { orgPath: true },
      body: body,
      callback: (resp: ECronJobDecorationGET) => {
        return fullOrgList
          ? _planToRefreshOrgCronJobList().cronJobListRequest
          : _planToRefreshKitCronJobList(kitId).cronJobListRequest;
      },
    },
  });
}
export default {
  cronRunramSchemaToRunrams: _cronRunramSchemaToRunrams,
  initCronJobProcessed: _initCronJobProcessed,
  initCronJobProcessedList: _initCronJobProcessedList,
  planToCreateCronJob: _planToCreateCronJob,
  planToDeleteCronJob: _planToDeleteCronJob,
  planToUpdateCronJob: _planToUpdateCronJob,
  planToRefreshKitCronJobList: _planToRefreshKitCronJobList,
  planToRefreshOrgCronJobList: _planToRefreshOrgCronJobList,
  planToDeleteCronJobDecoration: _planToDeleteCronJobDecoration,
  planToCreateCronJobDecoration: _planToCreateCronJobDecoration,
  planToUpdateCronJobDecoration: _planToUpdateCronJobDecoration,
};
